<template>
    <v-col>
        <v-row>
            <v-col>
                <v-row align="center">
                    <!-- <v-col align="left" class="flex-grow-0">
                        <v-select v-model="company" label="Company" :items="companyList" item-value="id"
                            item-text="name" clearable dense
                            class="blueBorderedSelect font14 textDecorationBold primaryColor" style="width: 210px; "
                            solo flat hide-details>
                            <template v-slot:label>
                                <span class="font14 textDecorationBold primaryColor"> Company </span>
                            </template>
                            <template v-slot:selection="{ item }">
                                <span class="font14 textDecorationBold primaryColor text-truncate">
                                    {{ item.name }}
                                </span>
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-list>
                                    <v-list-item-title>
                                        <v-row>
                                            <v-col>
                                                <span class="font14 textDecorationBold primaryColor text-truncate">
                                                    {{ data.item.name }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list>
                            </template>
                        </v-select>
                    </v-col> -->
                    <v-col align="left" class="flex-grow-0">
                        <v-select :items="getItemsList" dense v-model="options.items" color="primary"
                            item-color="primary"
                            style="max-width: 75px; font-size: 14px; font-family: CenturyGothicBold; font-weight: 500;"
                            height="43.54" hide-details outlined />
                    </v-col>
                    <v-col align="left">
                        <v-text-field class="blueBorderedSelect font14 textDecorationBold primaryColor"
                            label="Γρήγορη αναζήτηση ονόματος ή αριθμού..." outlined dense
                            prepend-inner-icon="mdi-phone-outline" hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row align="center">
                    <!-- <v-col align="right">
                        <v-btn class="btn-custom" color="error">
                            Νέα Κλήση
                        </v-btn>
                    </v-col>
                    <v-col class="flex-grow-0">
                        <v-btn class="btn-custom" key="fBtn2" outlined color="primary"
                            @click="() => openDialog('filters', null)">
                            <Icon icon="bx:filter-alt" height="25" outline color="#263573" />
                        </v-btn>
                    </v-col> -->
                    <v-col class="flex-grow-0" align="right"> 
                        <v-pagination class="main-pgt" v-model="options.page" :total-visible="6" :length="totalPages">
                        </v-pagination>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="left">
                <CustomTable :headers="headers" :totalPageVisible="6" :templates="templates" :data="tableData"
                    :loading="loading" class="row-height-85 " :supportDelete="true" :supportEdit="true"
                    :itemsPerPage="options.items" />
            </v-col>
        </v-row>
        <Filters v-if="dialog.type == 'filters' && dialog.open" :onCloseHandler="closeDialog"
            :filterValues="filterValues" :updateAttribute="updateFieldValue" />
    </v-col>
</template>

<script>
/* 
  Name: CallCenter
  Description: CallCenter view is a page for phone calls view and edit
  
  Components Used:
    Filters
    CustomTable

  Endpoints Functions Called:
  -CompaniesRepo
    get
*/
import { mapGetters } from "vuex";
import Filters from "@/components/Secretary/Filters.vue";
import SecretaryModel from "@/static/secretary/secretary.js";
import Repository from "@/services/Repository";
import CustomTable from "@/components/CustomTable/CustomTable";
import SecretaryModal from "@/static/secretary/secretary.js";

const CompaniesRepo = Repository.get("companies");
export default {
    name: "CallManager",
    components: {
        Filters,
        CustomTable,
    },
    data() {
        return {
            totalPages: 0,
            options: {
                page: 1,
                items: 20,
            },
            headers: SecretaryModal.headers,
            templates: SecretaryModal.templates,
            tableData: [
                {
                    date: "22-12-2022 | 18:30:32",
                    title: "Ioannis",
                    title2: "Γεώργιος",
                    subTitle: "Katsarakis",
                    subTitle2: "Γεωργίου",
                    isImg: "/general/Ellipse_1.png",
                    property: "Μονοκατοικία",
                    //region: "Δυτική Θεσσαλονίκη, Βόρειος Τομέας Αθηνών",
                    colorBar: "#77BD86",
                    progress: "10",
                    sizeRating: "5",
                    colorRating: "#243774",
                    disabledRating: "true",
                    // ratingValue: "4.9",
                    toTitle: "Ioannis",
                    condtionTitle: "Διαπραγμάτευση",
                    numTitle: 1,
                    priority: "Κανονικό",
                    bcolor: "blueStatusBox",
                    kind: "Ζήτηση",
                    //redRow: true,
                },
                {
                    date: "22-12-2022 | 18:30:32",
                    title: "Ioannis",
                    title2: "Γεώργιος",
                    subTitle: "Katsarakis",
                    subTitle2: "Γεωργίου",
                    isImg: "/general/Ellipse_1.png",
                    property: "Μονοκατοικία",
                    //region: "Δυτική Θεσσαλονίκη, Βόρειος Τομέας Αθηνών",
                    colorBar: "#77BD86",
                    progress: "10",
                    sizeRating: "5",
                    colorRating: "#243774",
                    disabledRating: "true",
                    //ratingValue: "4",
                    toTitle: "Ioannis",
                    condtionTitle: "Διαπραγμάτευση",
                    numTitle: 2,
                    priority: "Εύκολο",
                    bcolor: "greenStatusBox",
                    kind: "Επικοινωνία",
                    //redRow: true,
                },
            ],
            dialog: {
                open: false,
                type: null,
                data: null,
            },
            company: null,
            companyList: [],
            filterValues: SecretaryModel.filterValues,
        }
    },
    async created() {
        await this.initList();
    },
    computed: {
        ...mapGetters(["getItemsList"]),
        tagFilterArray() {
            return this.filterObject[0];
        },
    },
    methods: {
        async initList() {
            this.companyList = await this.fetchCompany();
        },
        async fetchCompany() {
            try {
                const resp = await CompaniesRepo.get({
                    action: 'get_companies'
                });
                return resp.data;
            } catch (err) {
                console.log(err, "Error");
            }
        },
        // async fetchSecretary() {
        //     try {
        //         this.filterObject = await SecretaryHelper.setFilterObject(
        //             this.filterValues
        //         );
        //         this.closeDialog();
        //     } catch (e) {
        //         throw e;
        //     }
        // },
        openDialog(type, data) {
            this.dialog = {
                open: true,
                type: type,
                data: data,
            };
        },
        closeDialog() {
            this.dialog = {
                open: false,
                type: null,
                data: null,
            };

        },
        updateFieldValue(key, value) {
            this.filterValues[key] = value;
        },
        async clearAllFilter() {
            for (const key in this.filterValues) {
                this.updateFieldValue(key, null);
            }
            await this.fetchContacts();
        },
    },

}
</script>

<style scoped>
.btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
}
</style>